import logo from './images/icon.png';
import workingimg from './images/working.png';

function App() {
  return (
    <div className='wrapper'>
    <div className="appbody">
      <img className="logo" src={logo} />
      <br/>
      <br/>
      <p>We're developing something awesome<br/>to support the Solana community.</p>
    
      <img className="workingimg" src={workingimg}/>
      </div>
      </div>
  );
}

export default App;
